import React, { FC } from 'react';
import styled from 'styled-components';

import ShapeText from '../components/ShapeText';
import ShapeImage, { containerVariants, getFloatVariant } from '../components/ShapeImage';

import { Variant } from 'framer-motion';
import { ImageDataLike } from 'gatsby-plugin-image';

const Layout = styled.section`
  display: flex;
  min-width: 100vw;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media ${(props) => props.theme.breakpoints.laptop} {
    overflow: visible;
    padding: 0 50px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 2000px;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};

  @media ${(props) => props.theme.breakpoints.laptop} {
    min-width: 700px;
    margin-bottom: 100px;
    height: 100%;
  }
`;

const BubbleContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 50px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: column;
  }
`;

const Title = styled.h2<{ maxWidth?: string }>`
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 27px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
    line-height: 55px;
    text-align: center;
  }
`;

const Heading = styled.h2<{ maxWidth?: string }>`
  font-weight: 700;
  font-size: 22px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  align-items: center;
  width: 100%;
  max-width: 200px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
    line-height: 55px;
    text-align: center;
    max-width: 340px;
  }
`;

const BubbleContainer = styled(ShapeText)<{ order?: number }>`
  margin-top: 30rem;
  height: 679px;
  width: 690px;
  z-index: 10;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 2;
  }
`;

const Paragraph = styled.p`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  max-width: 245px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    max-width: 340px;
  }
`;

const BigImage = styled(ShapeImage)<{ middleSection?: boolean }>`
  position: absolute;
  height: 234px;
  width: 234px;
  margin-top: ${(props) => (props.middleSection ? '-80px' : '320px')};
  right: 40%;

  @media ${(props) => props.theme.breakpoints.laptop} {
    position: relative;
    height: 274px;
    width: 274px;
    left: 0;
    margin-top: 0;
    margin: 0 50px;
  }
`;

const SmallImage = styled(ShapeImage)<{ middleSection?: boolean }>`
  position: absolute;
  left: 60%;
  height: 156px;
  width: 156px;
  margin-top: ${(props) => (props.middleSection ? '320px' : '-50px')};
  display: block;
  z-index: ${(props) => !props.middleSection && '3'};

  @media ${(props) => props.theme.breakpoints.laptop} {
    position: relative;
    height: 274px;
    width: 274px;
    display: none;
  }
`;

const Section = styled.div<{ middleSection?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.middleSection && '300px'};
  margin-bottom: ${(props) => props.middleSection && '200px'};
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    margin-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const floatVariant1 = getFloatVariant();
const floatVariant2 = getFloatVariant();
const floatVariant3 = getFloatVariant();

const ReasonToLove: FC<{
  middleSection?: boolean;
  bigImageUrl: ImageDataLike;
  smallImageUrl: ImageDataLike;
  shadowColor: string;
  color: string;
  order?: number;
  title: string;
  text: string;
  floatVariant: Variant;
}> = ({
  middleSection,
  bigImageUrl,
  smallImageUrl,
  shadowColor,
  color,
  order,
  title,
  text,
  floatVariant,
}) => {
  return (
    <Section middleSection={middleSection}>
      <SmallImage
        imageUrl={smallImageUrl}
        middleSection={middleSection}
        variants={{ ...containerVariants, float: floatVariant }}
      />
      <BubbleContainer shadowColor={shadowColor} color={color} order={order}>
        <Heading maxWidth='340px'>{title}</Heading>
        <Paragraph>{text}</Paragraph>
      </BubbleContainer>
      <BigImage
        imageUrl={bigImageUrl}
        middleSection={middleSection}
        variants={{ ...containerVariants, float: floatVariant }}
      />
    </Section>
  );
};

type WhyPeopleLoveProps = {
  data: {
    whyPeopleLoveImage1: ImageDataLike;
    whyPeopleLoveImage2: ImageDataLike;
    whyPeopleLoveImage3: ImageDataLike;
    whyPeopleLoveImage4: ImageDataLike;
    whyPeopleLoveImage5: ImageDataLike;
    whyPeopleLoveImage6: ImageDataLike;
  };
};
const WhyPeopleLove: FC<WhyPeopleLoveProps> = ({ data }) => {
  return (
    <Layout>
      <Container>
        <Title>Why people love illume.</Title>
        <BubbleContent>
          <ReasonToLove
            bigImageUrl={data.whyPeopleLoveImage1}
            smallImageUrl={data.whyPeopleLoveImage4}
            shadowColor='#fccfba'
            color='#EAC1AD'
            title='Simple but impactful.'
            text='Coming together to move others doesn’t need to be a big production. We believe
            there’s magic in simple yet heartfelt gestures.'
            floatVariant={floatVariant1}
          />
          <ReasonToLove
            bigImageUrl={data.whyPeopleLoveImage2}
            smallImageUrl={data.whyPeopleLoveImage5}
            shadowColor='#D4E3E6'
            color='#c1dade'
            title='Free for all.'
            text='illume is free for everyone involved—as gratitude sharing and love sending should
            be.'
            middleSection={true}
            order={2}
            floatVariant={floatVariant2}
          />
          <ReasonToLove
            bigImageUrl={data.whyPeopleLoveImage3}
            smallImageUrl={data.whyPeopleLoveImage6}
            shadowColor='#FAD952'
            color='#E7C84A'
            title='Notes are between you and the recipient.'
            text='We keep all contributor notes private so you can write freely and openly without
            limitations or fear of judgement.'
            floatVariant={floatVariant3}
          />
        </BubbleContent>
      </Container>
    </Layout>
  );
};

export default WhyPeopleLove;

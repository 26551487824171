import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ImageDataLike } from 'gatsby-plugin-image';

import analytics from '../helpers/analytics';

import ShapeImage from '../components/ShapeImage';
import LinkButton from '../components/LinkButton';
import Wave from '../components/Wave';

import { CREATE_CARD_LINK } from '../constants/links';

const TRACK_GET_STARTED = 'click get started - marketing home';
const occasions = ['everyday', 'anniversary', 'wedding', 'graduation', 'birthday'];

const Layout = styled.section`
  display: flex;
  padding: 0 50px;
  min-width: 100vw;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media ${(props) => props.theme.breakpoints.laptop} {
    overflow: visible;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-bottom: 120px;
  }
`;

const ImageText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 50px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    margin-bottom: 150px;
    margin-top: 0;
  }
`;

const HeadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  order: 2;

  @media ${(props) => props.theme.breakpoints.laptop} {
    position: absolute;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 100px;
  }
`;

const Heading = styled.h2`
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  align-items: center;
  width: 100%;
  line-height: 50px;
  z-index: 1000;
  order: 2;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 60px;
    line-height: 55px;
    text-align: center;
    order: 1;
    width: fit-content;
  }
`;

const AnimationText = styled.h2`
  font-weight: 700;
  color: ${(props) => props.theme.colors.orange};
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  align-items: center;
  width: 100%;
  line-height: 50px;
  z-index: 1000;
  order: 2;

  @media ${(props) => props.theme.breakpoints.laptop} {
    line-height: 55px;
    text-align: center;
    order: 1;
    color: ${(props) => props.theme.colors.lightPeach2};
    font-size: 50px;
    width: 300px;
  }
`;

const Button = styled.div`
  text-align: center;
  margin-bottom: 100px;
  margin-top: 30px;
  order: 3;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-top: 50px;
  }
`;

const Link = styled(LinkButton)`
  width: 100%;
  max-width: 264px;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 300px;
  }
`;

const WaveEnhanced = styled(Wave)`
  position: absolute;
  bottom: 0;
  margin-bottom: -2%;
  left: 0;
  width: 100%;
  z-index: 1001;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 280px;
  width: 280px;
  margin-left: 0;
  left: 10px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 100px;
    width: 275px;
    left: 0;
  }
`;

const Image1 = styled(ShapeImage)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 14;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
  height: 280px;
  width: 280px;
  order: 1;

  @media ${(props) => props.theme.breakpoints.laptop} {
    position: absolute;
    margin-left: 400px;
    order: 2;
    height: 270px;
    width: 270px;
    order: 2;
  }
`;

const Image2 = styled(ShapeImage)`
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
  height: 280px;
  width: 280px;
  order: 1;

  @media ${(props) => props.theme.breakpoints.laptop} {
    position: absolute;
    margin-left: 400px;
    order: 2;
    height: 270px;
    width: 270px;
    order: 2;
  }
`;

const Image3 = styled(ShapeImage)`
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
  height: 280px;
  width: 280px;
  order: 1;

  @media ${(props) => props.theme.breakpoints.laptop} {
    position: absolute;
    margin-left: 400px;
    order: 2;
    height: 270px;
    width: 270px;
    order: 2;
  }
`;

const Image4 = styled(ShapeImage)`
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
  height: 280px;
  width: 280px;
  order: 1;

  @media ${(props) => props.theme.breakpoints.laptop} {
    position: absolute;
    margin-left: 400px;
    order: 2;
    height: 270px;
    width: 270px;
    order: 2;
  }
`;

const Image5 = styled(ShapeImage)`
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
  height: 280px;
  width: 280px;
  order: 1;

  @media ${(props) => props.theme.breakpoints.laptop} {
    position: absolute;
    margin-left: 400px;
    order: 2;
    height: 270px;
    width: 270px;
    order: 2;
  }
`;

const container = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 1,
    },
  },
};

const item = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: 'spring', bounce: 0.5 },
  },
};

const ImageGroup: FC<{ inView: boolean } & MakeBrighterProps> = ({ inView, data }) => {
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.div variants={container} initial='hidden' animate={controls}>
      <Image5 imageUrl={data.makeBrigtherImage5} variants={item} animate={false} />
      <Image4 imageUrl={data.makeBrigtherImage4} variants={item} animate={false} />
      <Image3 imageUrl={data.makeBrigtherImage3} variants={item} animate={false} />
      <Image2 imageUrl={data.makeBrigtherImage2} variants={item} animate={false} />
      <Image1 imageUrl={data.makeBrigtherImage1} variants={item} animate={false} />
    </motion.div>
  );
};

type MakeBrighterProps = {
  data: {
    makeBrigtherImage1: ImageDataLike;
    makeBrigtherImage2: ImageDataLike;
    makeBrigtherImage3: ImageDataLike;
    makeBrigtherImage4: ImageDataLike;
    makeBrigtherImage5: ImageDataLike;
  };
};
const MakeBrighter: FC<MakeBrighterProps> = ({ data }) => {
  const [index, setIndex] = useState(0);
  const [completed, setCompleted] = useState(false);
  const { ref, inView } = useInView({
    threshold: 1,
  });

  const handleClickStart = () => {
    analytics.track(TRACK_GET_STARTED, { version: '2' });
  };

  useEffect(() => {
    if (inView) {
      const changeText = setInterval(() => {
        setIndex((prevIndex) => {
          if (prevIndex === occasions.length - 1) {
            setCompleted(true);
            return 4;
          }
          return prevIndex + 1;
        });
      }, 1000);
      return () => {
        clearInterval(changeText);
      };
    } else {
      setIndex(0);
      setCompleted(false);
    }
  }, [inView, completed]);

  return (
    <Layout>
      <Container>
        <HeadingContainer ref={ref}>
          <Heading>Make someone&apos;s </Heading>
          <AnimationText>{occasions[index]}</AnimationText>
          <Heading> {''} brighter.</Heading>
        </HeadingContainer>
        <ImageText>
          <ImageContainer>
            <ImageGroup inView={inView} data={data} />
          </ImageContainer>
        </ImageText>
        <Button>
          <Link href={CREATE_CARD_LINK} onClick={handleClickStart}>
            start a group card
          </Link>
        </Button>
      </Container>
      <WaveEnhanced color='dark' />
    </Layout>
  );
};

export default MakeBrighter;

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot } from 'pure-react-carousel';
import { StaticImage } from 'gatsby-plugin-image';

import 'pure-react-carousel/dist/react-carousel.es.css';

import backArrow from '../icons/back-arrow.svg';
import nextArrow from '../icons/next-arrow.svg';

const Layout = styled.section`
  display: flex;
  min-width: 100vw;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;

  @media ${(props) => props.theme.breakpoints.laptop} {
    overflow: visible;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-bottom: 100px;
    max-width: 1000px;
  }
`;

const Heading = styled.h2`
  font-weight: 500;
  font-size: 22px;
  line-height: 1.44;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  align-items: center;
  width: 100%;
  padding: 0 50px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
    text-align: center;
    max-width: 1000px;
  }
`;

const LeftSection = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  order: 1;
  align-items: center;
  text-align: center;
  margin-top: 20px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-top: 0;
    align-items: center;
  }
`;

const RightSection = styled.section`
  display: flex;
  flex-direction: column;
  order: 2;
  display: flex;
  align-items: center;
  width: 207px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-top: 0;
    margin-left: 0;
    width: 330px;
  }
`;

const Paragraph = styled.p`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  max-width: 250px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    max-width: 480px;
  }
`;

const SliderWrapper = styled.div`
  overflow: visible;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.lightPeach2};

  @media ${(props) => props.theme.breakpoints.laptop} {
    overflow: hidden;
  }
`;

const ContainerCard = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  padding: 0;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    padding: 60px 60px 50px;
    width: fit-content;
  }
`;

const Nav = styled.nav`
  position: absolute;
  top: 220px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  height: 330px;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 100%;
    height: 280px;
  }
`;

const ButtonNav = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`;

const Arrow = styled.img`
  width: 13.47px;
  height: 24px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 20.21px;
    height: 36px;
  }
`;

const SharedStyles = css`
  width: 50px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: black;
  border: 0;
  font-size: 40px;
  outline: none;
  transition: opacity 0.35s;
  &:disabled {
    opacity: 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 50px;
  }
`;

const DotsWrapper = styled.div`
  width: 100%;
  bottom: 0;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 10px;

  .carousel__dot {
    height: 8px;
    width: 8px;
    margin: 3px;
    border-radius: 50%;
    border: 1px solid #282829;

    @media ${(props) => props.theme.breakpoints.laptop} {
      height: 15px;
      width: 15px;
    }
  }

  .carousel__dot--selected {
    background-color: #282829;
  }
`;

const NextButton = styled(ButtonNext)`
  ${SharedStyles}
`;

const BackButton = styled(ButtonBack)`
  ${SharedStyles}
`;

const Card = styled(Slide)`
  height: 600px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 100%;
  }
`;

const SliderCard: FC<{
  index: number;
  heading: string;
  body: string;
}> = ({ index, heading, body, children }) => {
  return (
    <Card index={index}>
      <ContainerCard>
        <LeftSection>
          <Heading>{heading}</Heading>
          <Paragraph> {body} </Paragraph>
        </LeftSection>
        <RightSection>{children}</RightSection>
      </ContainerCard>
    </Card>
  );
};

const ThreeSliders: FC = () => {
  return (
    <Layout>
      <Container>
        <Heading>The experience is simple and delightful—for you and for them.</Heading>
        <SliderWrapper>
          <CarouselProvider
            naturalSlideHeight={500}
            naturalSlideWidth={1000}
            totalSlides={3}
            visibleSlides={1}
          >
            <Slider>
              <SliderCard
                index={1}
                heading='1. Create'
                body='It only takes 30 seconds to start your group card. Fill in the name of the lucky
      person receiving the card, and set a reminder so your group can know when to
      contribute their notes.'
              >
                <StaticImage
                  src='../images/how-it-works-1.png'
                  alt='how-it-works-1'
                  width={235}
                  height={386}
                />
              </SliderCard>
              <SliderCard
                index={2}
                heading='2. Customize'
                body='This is where the magic happens! This is the blank canvas where you paint with your
              words and write whatever you want. Optionally upload a photo or video, and select your
              favorite color scheme to make it uniquely yours.'
              >
                <StaticImage
                  src='../images/how-it-works-2.png'
                  alt='how-it-works-3'
                  width={235}
                  height={386}
                />
              </SliderCard>
              <SliderCard
                index={3}
                heading='3. Invite'
                body='Easily invite others to contribute via email and by copying the shareable link. Once
        your reminder to send arrives, put the email address of the recipient and voila, the
        day-making experience arrives in their inbox.'
              >
                <StaticImage
                  src='../images/how-it-works-3.png'
                  alt='how-it-works-3'
                  width={235}
                  height={386}
                />
              </SliderCard>
            </Slider>
            <Nav>
              <ButtonNav>
                <BackButton>
                  <Arrow src={backArrow} />
                </BackButton>
                <NextButton>
                  <Arrow src={nextArrow} />
                </NextButton>
              </ButtonNav>
              <DotsWrapper>
                {[...Array(3)].map((_, index) => (
                  <Dot key={index} slide={index}></Dot>
                ))}
              </DotsWrapper>
            </Nav>
          </CarouselProvider>
        </SliderWrapper>
      </Container>
    </Layout>
  );
};

export default ThreeSliders;

import React, { FC } from 'react';
import styled from 'styled-components';

import analytics from '../helpers/analytics';

import { SIGN_IN_LINK, INDEX_LINK, POSTS_LINK } from '../constants/links';

import { StaticImage } from 'gatsby-plugin-image';
import { theme } from '../styles/theme';

const TRACK_LOGIN = 'login - marketing';

const Container = styled.header<{ scrolled: boolean; color: string }>`
  position: fixed;
  -webkit-backface-visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  background-color: ${(props) => props.scrolled && props.color};
  transition: background-color 200ms linear;

  @media ${(props) => props.theme.breakpoints.laptop} {
    position: fixed;
    padding: 27px 43px;
    background-color: transparent;
  }
`;

const LogoWrapper = styled.div`
  width: 45px;
  height: 44px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 72px;
    height: 71px;
  }
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 14px;
  font-weight: bold;
  line-height: 2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Section = styled.div`
  display: flex;
`;

const BlogLink = styled.a<{ isBlogPage: boolean }>`
  color: ${(props) =>
    props.isBlogPage ? props.theme.colors.blackTextLight : props.theme.colors.blackText};
  font-size: 14px;
  font-weight: bold;
  line-height: 2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
`;

const NewHeader: FC<{ scrolled: boolean; color: string; isBlogPage: boolean }> = ({
  scrolled,
  color = theme.colors.lightPeach2,
  isBlogPage,
}) => {
  const handleClickLogin = () => {
    analytics.track(TRACK_LOGIN);
  };

  return (
    <Container scrolled={scrolled} color={color}>
      <Section>
        <Link href={INDEX_LINK}>
          <LogoWrapper>
            <StaticImage
              src='../images/new-logo.png'
              alt='illume'
              width={144}
              height={142}
              breakpoints={[144]}
              placeholder='none'
            />
          </LogoWrapper>
        </Link>
      </Section>
      <Section>
        <BlogLink href={POSTS_LINK} isBlogPage={isBlogPage}>
          blog
        </BlogLink>
        <Link href={SIGN_IN_LINK} onClick={handleClickLogin}>
          Login / Sign up
        </Link>
      </Section>
    </Container>
  );
};

export default NewHeader;

import React, { FC } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import instagram from '../icons/instagram.svg';

import {
  CONTACT_US_LINK,
  PRIVACY_LINK,
  TERMS_LINK,
  INSTAGRAM_LINK,
  FAQS_LINK,
} from '../constants/links';

const Layout = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1001;
`;

const Container = styled.footer`
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 10%;
  display: flex;
  font-size: 14px;
  line-height: 1.57;
  flex-direction: column;
  align-items: center;
  background-color: #505b71;
  padding-bottom: 50px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    align-items: flex-start;
    padding: 36px 64px;
  }
`;

const LeftSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  order: 2;
  align-items: center;
  text-align: center;
  order: 2;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 1;
    margin-top: 0;
    align-items: flex-start;
    order: 1;
  }
`;

const RightSection = styled.section`
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: column;
  order: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: 2;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 2;
    margin-top: 0;
    margin-left: 0;
    order: 1;
    align-items: flex-end;
  }
`;

const Copyright = styled.h3`
  color: ${(props) => props.theme.colors.lightPeach2};
  order: 3;
  text-align: center;
  margin-top: 16px;
  max-width: 250px;
  font-weight: 400;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 2;
    text-align: left;
    margin-top: 0;
    max-width: none;
    margin-right: -30px;
  }
`;

const LinksContainer = styled.div`
  color: ${(props) => props.theme.colors.lightPeach2};
  order: 2;
  margin-top: 16px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 1;
    margin-top: 0;
  }
`;

const TextLink = styled.a`
  padding: 0 12px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 14px
  font-weight: 700;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 12px;
  }
`;

const LogoWrapper = styled.div`
  width: 45px;
  height: 44px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 72px;
    height: 71px;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  order: 4;
  margin-top: 50px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-top: 2px;
  }
`;

const IconLink = styled.a`
  padding: 0 8px;
  line-height: 1;
`;

const Instagram = styled.img`
  width: 25px;
  height: 25px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 18px;
    height: 18px;
  }
`;

const NewFooter: FC = () => (
  <Layout>
    <Container>
      <LeftSection>
        <LogoWrapper>
          <StaticImage
            src='../images/new-logo.png'
            alt='illume'
            width={144}
            height={142}
            breakpoints={[144]}
            placeholder='none'
          />
        </LogoWrapper>
      </LeftSection>
      <RightSection>
        <LinksContainer>
          <TextLink href={CONTACT_US_LINK} target='_blank'>
            contact us
          </TextLink>
          <TextLink href={PRIVACY_LINK}>privacy</TextLink>
          <TextLink href={TERMS_LINK}>terms</TextLink>
          <TextLink href={FAQS_LINK}>FAQs</TextLink>
        </LinksContainer>
        <Copyright>copyright 2022. all rights reserved. dedicated to those who shine.</Copyright>
      </RightSection>
      <IconsContainer>
        <IconLink href={INSTAGRAM_LINK} target='_blank' rel='noopener'>
          <Instagram src={instagram} alt='instagram' />
        </IconLink>
      </IconsContainer>
    </Container>
  </Layout>
);

export default NewFooter;

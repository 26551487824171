import React, { FC, useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import LinkButton from '../components/LinkButton';
import ShapeImage, { containerVariants, getFloatVariant } from '../components/ShapeImage';
import ShapeText from '../components/ShapeText';

import blob from '../icons/blob.svg';

import analytics from '../helpers/analytics';

import { CREATE_CARD_LINK } from '../constants/links';
import gifIllume from '../images/what-is-a-group-card.gif';
import { isMobile } from '../helpers/utils';
import { ImageDataLike } from 'gatsby-plugin-image';

const NOTES_STARTING_COUNT = 10000;
const TRACK_GET_STARTED = 'click get started - marketing home';

const Layout = styled.section`
  display: flex;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: right-top;
  overflow-x: hidden;

  @media ${(props) => props.theme.breakpoints.laptop} {
    overflow: visible;
    background-repeat: no-repeat;
    background-position: right top;
  }
`;

const Container = styled.div`
  margin-top: 200px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-top: 100px;
    margin-bottom: 150px;
  }
`;

const MiddleSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
`;

const BubbleContainer = styled(ShapeText)`
  margin-top: 30rem;
  height: 679px;
  width: 690px;
  z-index: 1;
  align-items: center;
`;

const Image = styled(ShapeImage)`
  margin-left: 150px;
  height: 280px;
  width: 280px;
  display: none;

  @media ${(props) => props.theme.breakpoints.laptop} {
    display: block;
  }
`;

const ImageTop = styled(ShapeImage)`
  position: absolute;
  height: 152px;
  width: 152px;
  z-index: 2;
  left: 50%;
  top: 120px;
  transform: translate(-10%, -40%);

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 166px;
    width: 166px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const ImageBottom = styled(ShapeImage)`
  position: absolute;
  height: 179px;
  width: 179px;
  top: 570px;
  margin-right: 20%;

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 143px;
    width: 143px;
    top: 550px;
    right: 60%;
  }
`;

const Blob = styled.img`
  position: absolute;
  top: -50px;
  left: 35%;
  width: 891px;
  height: 927px;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.laptop} {
    top: -300px;
    width: 1366px;
    height: 1366px;
    left: 50%;
  }
`;

const Heading = styled.p`
  font-weight: 700;
  font-size: 22px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
    line-height: 55px;
    text-align: center;
  }
`;

const Paragraph = styled.p`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  max-width: 319px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    max-width: 453px;
  }
`;

const Description = styled.p`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  max-width: 243px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    max-width: 690px;
  }
`;

const GifIllume = styled.img`
  margin-top: 260px;
  margin-bottom: 64px;
  height: 100%;
  width: 100%;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-top: 180px;
    height: 465px;
    width: 750px;
  }
`;

const Link = styled(LinkButton)`
  margin-top: 32px;
  width: 100%;
  max-width: 264px;
`;

const Info = styled.h3`
  margin-top: 8px;
  width: 100%;
  max-width: 264px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.58;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
`;

const floatVariant1 = getFloatVariant();
const floatVariant2 = getFloatVariant();
const floatVariant3 = getFloatVariant();

type MakeSomeoneDayProps = {
  data: {
    image1: ImageDataLike;
    image2: ImageDataLike;
    image3: ImageDataLike;
  };
};
const MakeSomeoneDay: FC<MakeSomeoneDayProps> = ({ data }) => {
  const notesRef = useRef<HTMLHeadingElement>(null);
  const [notesInViewport, setNotesInViewport] = useState<boolean>(false);
  const [notesNumber, setNotesNumber] = useState<number>(NOTES_STARTING_COUNT);
  const mobile = isMobile();
  const textStyle = mobile ? (
    <>
      <br />
      <br />
      <b>day-changing,</b>
      <br /> <b>mega heartwarming,</b>
      <br /> <b>gratitude showering,</b>
      <br /> <b>&quot;wow, I feel loved&quot;</b>
      <br />
      <br />
    </>
  ) : (
    <b>day-changing, mega heartwarming, gratitude showering, &quot;wow, I feel loved&quot;</b>
  );
  /*const {
    notesApi: { totalNumberOfNotes },
  } = useStaticQuery(
    graphql`
      query Notes {
        notesApi {
          totalNumberOfNotes
        }
      }
    `
  );*/
  const totalNumberOfNotes = 0;
  const [totalNotes, setTotalNotes] = useState<number>(totalNumberOfNotes);

  const handleClickStart = () => {
    analytics.track(TRACK_GET_STARTED, { version: '2' });
  };

  useEffect(() => {
    const event = () => {
      if (notesRef.current) {
        const { top } = notesRef.current.getBoundingClientRect();
        setNotesInViewport(top - window.innerHeight < 0);
      }
    };

    window.addEventListener('scroll', event);

    return () => {
      window.removeEventListener('scroll', event);
    };
  }, []);

  useEffect(() => {
    async function fetchTotalNotesAPI() {
      try {
        let response = await fetch('https://web.illumenotes.com/api/resources/notes'); // it blocked by cors if run in local
        const resultData = await response.json();
        const { total: updatedTotalNotes } = resultData;
        setTotalNotes(updatedTotalNotes);
      } catch (error) {
        console.error(error);
      }
    }

    fetchTotalNotesAPI();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (totalNotes > 0 && !interval) {
      const step = Math.floor(totalNotes / 500);
      let current = NOTES_STARTING_COUNT;

      interval = setInterval(() => {
        current += step;

        if (current > totalNotes && interval) {
          setNotesNumber(totalNotes);
          clearInterval(interval);
          return;
        }

        setNotesNumber(current);
      }, 15);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [totalNotes, notesInViewport]);

  return (
    <Layout>
      <Container>
        <Blob src={blob} />
        <ImageTop
          imageUrl={data.image2}
          mobileUrl={data.image1}
          variants={{ ...containerVariants, float: floatVariant1 }}
        />
        <MiddleSection>
          <BubbleContainer color={'#c1dade'} shadowColor={'#D4E3E6'}>
            <Heading>Make someone&apos;s day, together.</Heading>
            <Paragraph>
              Send a beautiful digital group card to celebrate an individual for an occasion, or
              just because.
            </Paragraph>
            <Link href={CREATE_CARD_LINK} onClick={handleClickStart}>
              start a group card
            </Link>
            <Info ref={notesRef}>
              {notesNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} notes sent with love
            </Info>
          </BubbleContainer>
          <Image imageUrl={data.image1} variants={{ ...containerVariants, float: floatVariant2 }} />
        </MiddleSection>
        <ImageBottom
          imageUrl={data.image3}
          variants={{ ...containerVariants, float: floatVariant3 }}
        />
        <GifIllume src={gifIllume} alt='gif-card' />
        <Heading>What is an illume group card?</Heading>
        <Description>
          An illume group card isn&apos;t just another group digital card. It&apos;s a {textStyle}
          experience. It&apos;s where individuals come together and contribute meaningfully to a
          card for someone who shines.
          <br />
          <br />
          That&apos;s illume.
        </Description>
      </Container>
    </Layout>
  );
};

export default MakeSomeoneDay;

import React, { useState, useEffect, FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

import { theme } from '../styles/theme';

import MetaTags from '../components/MetaTags';
import NewHeader from '../components/NewHeader';
import CreateCardButton from '../components/CreateCardButton';

import MakeSomeoneDay from '../sections/MakeSomeoneDay';
import ThreeSliders from '../sections/ThreeSliders';
import WhyPeopleLove from '../sections/WhyPeopleLove';
import MakeBrighter from '../sections/MakeBrigther';
import NewFooter from '../sections/NewFooter';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  scroll-behavior: smooth;

  @media ${(props) => props.theme.breakpoints.laptop} {
    overflow-x: hidden;
    -webkit-backface-visibility: hidden;
  }
`;

type IndexPageProps = {
  data: {
    image1: ImageDataLike;
    image2: ImageDataLike;
    image3: ImageDataLike;

    whyPeopleLoveImage1: ImageDataLike;
    whyPeopleLoveImage2: ImageDataLike;
    whyPeopleLoveImage3: ImageDataLike;
    whyPeopleLoveImage4: ImageDataLike;
    whyPeopleLoveImage5: ImageDataLike;
    whyPeopleLoveImage6: ImageDataLike;

    makeBrigtherImage1: ImageDataLike;
    makeBrigtherImage2: ImageDataLike;
    makeBrigtherImage3: ImageDataLike;
    makeBrigtherImage4: ImageDataLike;
    makeBrigtherImage5: ImageDataLike;
  };
};
const IndexPage2: FC<IndexPageProps> = ({ data }) => {
  const [scrolled, setScrolled] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener('scroll', changeBackground);
  });

  return (
    <ThemeProvider theme={theme}>
      <MetaTags title='better together' />
      <Layout>
        <NewHeader scrolled={scrolled} color={theme.colors.lightPeach2} isBlogPage={false} />
        <MakeSomeoneDay data={data} />
        <ThreeSliders />
        <WhyPeopleLove data={data} />
        <MakeBrighter data={data} />
        <NewFooter />
        <CreateCardButton show={scrolled} />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage2;

export const pageQuery = graphql`
  fragment BlobImage on ImageSharp {
    gatsbyImageData(width: 720, quality: 50)
  }

  query {
    image1: file(relativePath: { eq: "section-b-small-1.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    image2: file(relativePath: { eq: "recipient-1.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    image3: file(relativePath: { eq: "selfie-1.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }

    whyPeopleLoveImage1: file(relativePath: { eq: "family-1.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    whyPeopleLoveImage2: file(relativePath: { eq: "friends-1.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    whyPeopleLoveImage3: file(relativePath: { eq: "selfie-2.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    whyPeopleLoveImage4: file(relativePath: { eq: "recipient-2.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    whyPeopleLoveImage5: file(relativePath: { eq: "friends-2.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    whyPeopleLoveImage6: file(relativePath: { eq: "recipient-1.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }

    makeBrigtherImage1: file(relativePath: { eq: "birthday.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    makeBrigtherImage2: file(relativePath: { eq: "graduation.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    makeBrigtherImage3: file(relativePath: { eq: "wedding.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    makeBrigtherImage4: file(relativePath: { eq: "anniversary.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
    makeBrigtherImage5: file(relativePath: { eq: "everyday.jpg" }) {
      childImageSharp {
        ...BlobImage
      }
    }
  }
`;

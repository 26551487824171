import React, { FC, useRef } from 'react';
import {
  AnimationControls,
  motion,
  Target,
  TargetAndTransition,
  useViewportScroll,
  VariantLabels,
  Variants,
} from 'framer-motion';
import styled from 'styled-components';
import { containerVariants, CustomHoverVariantParams, useScrollScale } from './ShapeImage';

const Blob: FC<{
  color: string;
  shadowColor?: string;
}> = ({ shadowColor, color, ...props }) => {
  const { scrollY } = useViewportScroll();
  const elementRef = useRef<SVGSVGElement>(null);
  const scrollScale = useScrollScale(true, scrollY, elementRef);

  return (
    <motion.svg
      {...props}
      viewBox='0 0 574 552'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      variants={containerVariants}
      whileHover='hover'
      whileTap='hover'
      custom={{ scale: scrollScale, maxScale: 1.05 } as CustomHoverVariantParams}
      ref={elementRef}
      animate='scroll'
    >
      <path
        d='M29.7861 333.864C27.3505 383.198 63.6253 435.735 122.961 475.615C186.286 518.182 256.504 535.385 332.163 530.477C375.796 527.687 416.89 515.806 451.403 487.239C473.842 468.693 491.202 445.912 506.178 421.115C532.607 377.412 554.735 331.849 567.224 281.999C584.532 212.983 567.638 152.077 523.487 97.8869C477.21 41.114 415.698 12.2885 344.03 2.78326C293.919 -3.88071 245.103 1.38845 197.687 18.9524C139.802 40.3908 98.2419 80.013 72.0722 135.081C45.9543 189.943 31.7035 247.955 29.7861 333.864Z'
        fill={color}
      />
      {shadowColor && (
        <path
          d='M0.115425 354.405C-2.32017 403.739 33.9547 456.276 93.2899 496.157C156.615 538.723 226.833 555.926 302.492 551.018C346.126 548.229 387.22 536.347 421.733 507.78C444.171 489.234 461.531 466.453 476.508 441.657C502.936 397.953 525.064 352.39 537.553 302.54C554.861 233.524 537.968 172.618 493.816 118.428C447.54 61.6553 386.028 32.8297 314.359 23.3245C264.248 16.6605 215.432 21.9297 168.016 39.4937C110.132 60.932 68.5712 100.554 42.4015 155.622C16.2836 210.484 2.03281 268.497 0.115425 354.405Z'
          fill={shadowColor}
        />
      )}
    </motion.svg>
  );
};

const Container = styled.div<{ order?: number }>`
  position: relative;
  order: ${(props) => props.order && props.order};

  width: 400px;
  height: 400px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 600px;
    height: 600px;
  }
`;

const Component = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ShapeText: FC<{
  color: string;
  shadowColor?: string;
  order?: number;
  variants?: Variants;
  initial?: boolean | Target | VariantLabels;
  animate?: AnimationControls | TargetAndTransition | VariantLabels | boolean;
}> = ({ color, shadowColor, children, order }) => {
  return (
    <Container order={order}>
      <Blob color={color} shadowColor={shadowColor} />
      <Component>{children}</Component>
    </Container>
  );
};

export default ShapeText;
